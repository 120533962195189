import React, { useState } from "react";
import {
  AppBar,
  Alert,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
  Divider,
  Chip,
  Stack
} from "@mui/material";

import { useAuth } from "../context/AuthContext";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accessKey, setAccessKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const { authData, login, logout } = useAuth();


  const handleClick = (event) => {
    clearState();
    reset();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    clearState();
    setAnchorEl(null);
  };

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();
    validateS3Connection(accessKey, secretKey)
      .then((result) => {
        console.log(result);
        if (result.success) {
          setSuccess(true);
          setMessage(result.message);
          login(accessKey, secretKey, true);
          handleClose();
        } else {
          logout();
          setError(true);
          setMessage(result.message);
        }
      })
      .catch((error) => {
        logout();
        setError(true);
        setMessage("Error Validating S3 Connection");
      });
  };

  const clearState = () => {
    setError(false);
    setSuccess(false);
    setMessage("");
  };

  const reset = () => {
    setAccessKey("");
    setSecretKey("");
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "#FFF",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src="caiso_bulk_logo.png" alt="OASIS Data" />
        </Typography>
        <div></div>
        <Stack direction="row" spacing={2}>
         <Chip color="success" label={process.env.REACT_APP_ENV} />
      
      
      {authData.isLoggedIn ? (
          <Button
            size="small"
            sx={{ alignContent: "center" }}
            onClick={() => logout()}
            variant="outlined"
            color="error"
            aria-controls="login-menu"
            aria-haspopup="true"
          >
            Disconnect
          </Button>
        ) : (
          <Button
            size="small"
            sx={{ alignContent: "center" }}
            variant="outlined"
            color="success"
            aria-controls="login-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Connect with AWS
          </Button>
        )}
       </Stack>

      
        <Menu
          id="login-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 250, // Set your desired fixed width here
            },
          }}
        >
         <form onSubmit={handleLoginFormSubmit}>
  <MenuItem>
    <TextField
      id="accessKey"
      name="accessKey"
      label="Access Key"
      value={accessKey}
      required
      variant="standard"
      onChange={(e) => setAccessKey(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          // Focus on the next input element (Secret Key)
          document.getElementById('secretKey').focus();
        }
      }}
    />
  </MenuItem>
  <MenuItem>
    <TextField
      id="secretKey"
      name="secretKey"
      label="Secret Key"
      value={secretKey}
      required
      variant="standard"
      onChange={(e) => setSecretKey(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault();
          // Focus on the previous input element (Access Key)
          document.getElementById('accessKey').focus();
        }
      }}
    />
  </MenuItem>

  {success ? (
    <Alert severity="success" sx={{ alignItems: "center" }}>
      {message}
    </Alert>
  ) : null}
  {error ? (
    <Alert severity="error" sx={{ alignItems: "center" }}>
      {message}
    </Alert>
  ) : null}

  <MenuItem>
    <Button
      type="submit"
      variant="contained"
      color="success"
      size="small"
      disableElevation
    >
      LOG IN
    </Button>
  </MenuItem>
</form>

        </Menu>
      </Toolbar>
      <Divider sx={{ height: 2 }} />
    </AppBar>
  );
};

const validateS3Connection = async (accessKeyId, secretAccessKey) => {
  const s3Client = new S3Client({
    apiVersion: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET_VERSION,
    region: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET_REGION,
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    },
  });

  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET,
    Key: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET_KEY,
    RequestPayer: process.env.REACT_APP_OASIS_BULK_DATA_S3_REQUEST_PAYER,
  });

  try {
    const response = await s3Client.send(command);
    return { success: true, message: "S3 connection validated successfully." };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export default Header;