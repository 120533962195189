
export const GROUP_INFO = {
    DAM_ALL: {
        heading: "All Day Ahead Market Prices",
        description: "The Day-Ahead Market (DAM) encompasses several Day-Ahead processes, including the Integrated Forward Market (IFM), Residual Unit Commitment (RUC), and Market Power Mitigation (MPM). The Locational Marginal Price (LMP) data is in hourly intervals at various Price Nodes (PNodes) or Aggregate Price Nodes (APNodes). LMP zip files contain five csv files: LMP, Marginal Cost of Congestion (MCC), Marginal Cost of Energy (MCE), Marginal Cost of Losses (MCL), and Marginal Cost of Green House Gases (MGHG).",
    },
    DAM_LMP: {
        heading: "DA Locational Marginal Prices",
        description: "'DA LMPs' is pricing data from the IFM process in hourly intervals at the nodal level.",
    },
    RUC_LMP: {
        heading: "Residual Unit Commitment Prices",
        description: "'RUC LMPs' is pricing data from the RUC process in hourly intervals at the nodal level.",
    },
    DAM_SPTIE_LMP: {
        heading: "DA Scheduling Point/Tie Combination Prices ",
        description: " 'SP Tie Combination Prices' is pricing data at Scheduling Point nodes in hourly intervals.",
    },
    DAM_MPM_LMP: {
        heading: "DA MPM Locational Marginal Prices",
        description: "'MPM Prices' is pricing data from the Day Ahead Market Power Mitigation process in hourly intervals at the nodal level.",
    },
    RTM_ALL: {
        heading: "All Real Time Market Prices",
        description: "The Real Time Market (RTM) encompasses several Real-time processes, including the Hour-Ahead Scheduling Process (HASP), Fifteen-Minute Market (FMM) also known as Real-Time Pre-Dispatch (RTPD), and Real-Time Dispatch (RTD). The Locational Marginal Price (LMP) data is in hourly (HASP), fifteen-minute (HASP, RTPD) or five-minute (RTD) intervals at various Price Nodes (PNodes) or Aggregate Price Nodes (APNodes). LMP zip files contain five csv files: LMP, Marginal Cost of Congestion (MCC), Marginal Cost of Energy (MCE), Marginal Cost of Losses (MCL), and Marginal Cost of Green House Gases (MGHG).",
    },
    RTM_LMP: {
        heading: "RTD Locational Marginal Prices",
        description: "'RTD Prices' is pricing data from the Real-Time Dispatch process in five-minute intervals at the nodal level per hour of the specified trade date.",
    },
    RTD_SPTIE_LMP: {
        heading: "RTD Scheduling Point/Tie Combination Prices",
        description: " 'RTD SP Tie Combination Prices' is pricing data from the RTD process at Scheduling Point nodes in five-minute intervals level per hour of the specified trade date.",
    },
    RTD_FLEX_RAMP_NODAL_PRC: {
        heading: "RTD Flex Ramp Nodal Prices",
        description: "'RTD Flex Ramp Nodal Prices' is pricing data from the RTD process for the Flex Ramp Up (FRU) and Flex Ramp Down (FRD) products in five-minute intervals at the nodal level per hour of the specified trade date.",
    },

    RTD_FLEX_RAMP_SPTIE_NODAL_PRC: {
        heading: "RTD Flex Ramp SP Tie Nodal Prices ",
        description: "'RTD Flex Ramp SP Tie Nodal Prices' is pricing data from the RTD process for the Flex Ramp Up (FRU) and Flex Ramp Down (FRD) products in five-minute intervals at Scheduling Points per hour of the specified trade date.",
    },
    RTM_LAP: {
        heading: "LAP Prices",
        description: "",
    },
    RTPD_LMP: {
        heading: "FMM Locational Marginal Prices",
        description: "'FMM Prices' is pricing data from the Real-Time Pre-Dispatch (RTPD) process in fifteen-minute intervals at the nodal level per hour of the specified trade date.",
    },
    RTPD_SPTIE_LMP: {
        heading: "FMM SP Tie Combination Prices",
        description: "'FMM SP Tie Combination Prices' is pricing data from the RTPD process at Scheduling Point nodes in fifteen-minute intervals level per hour of the specified trade date.",
    },
    RTPD_FLEX_RAMP_NODAL_PRC: {
        heading: "FMM Flex Ramp Nodal Prices",
        description: "'FMM Flex Ramp Nodal Prices' is pricing data from the RTPD process for the Flex Ramp Up (FRU) and Flex Ramp Down (FRD) products in fifteen-minute intervals at the nodal level per hour of the specified trade date.",
    },

    RTPD_FLEX_RAMP_SPTIE_NODAL_PRC: {
        heading: "FMM Flex Ramp SP Tie Nodal Prices",
        description: "'FMM Flex Ramp SP Tie Nodal Prices' is pricing data from the RTPD process for the Flex Ramp Up (FRU) and Flex Ramp Down (FRD) products in fifteen-minute intervals at Scheduling Points per hour of the specified trade date.",
    },
    RTPD_MPM_LMP: {
        heading: "FMM MPM Prices",
        description: "'FMM MPM Prices' is pricing data from the RTPD Market Power Mitigation process in fifteen-minute intervals at the nodal level.",
    },
    HASP_LMP: {
        heading: "Hourly Locational Marginal Prices",
        description: "Hourly Prices' is pricing data from the HASP process in fifteen-minute intervals at the nodal level.",
    },
    HASP_PRC_AS: {
        heading: "Hourly AS Clearing Prices",
        description: "'Hourly AS Clearing Prices' is pricing data for Ancillary Services (AS) from the HASP process in hourly intervals at the AS region level.",
    },
    HASP_MPM_LMP: {
        heading: "Hourly MPM Locational Marginal Prices",
        description: "'Hourly MPM Prices' is pricing data from the HASP Market Power Mitigation process in fifteen-minute intervals at the nodal level.",
    },



}




export const HOME_MENU = [{
    title: 'Home',
    key: 'home',
    icon: '',
}]

export const DAM_MENU = [{
        title: 'All Day Ahead Market Prices',
        heading: 'All Day Ahead Market Prices',
        key: 'DAM_ALL',
        name: 'DAM_ALL',
    },
    {
        title: 'LMPs',
        heading: 'Locational Marginal Prices',
        key: 'DAM_LMP',
        name: 'DAM_LMP',
    },
    {
        title: 'RUC Prices',
        key: 'RUC_LMP',
        name: 'RUC_LMP',
    },
    {
        title: 'SP Tie Combination Prices',
        key: 'DAM_SPTIE_LMP',
        name: 'DAM_SPTIE_LMP',
    },


    {
        title: 'MPM LMPs',
        key: 'DAM_MPM_LMP',
        name: 'DAM_MPM_LMP',
    },


]
export const RTD_MENU = [{
        title: 'LMPs',
        key: 'RTM_LMP',
        name: 'RTM_LMP',
    },
    {
        title: 'SP Tie Combination Prices',
        key: 'RTD_SPTIE_LMP',
        name: 'RTD_SPTIE_LMP',

    },
    {
        title: 'Flex Ramp Nodal Prices',
        key: 'RTD_FLEX_RAMP_NODAL_PRC',
        name: 'RTD_FLEX_RAMP_NODAL_PRC',

    },
    {
        title: 'Flex Ramp SP Tie Nodal Prices ',
        key: 'RTD_FLEX_RAMP_SPTIE_NODAL_PRC',
        name: 'RTD_FLEX_RAMP_SPTIE_NODAL_PRC',

    },

    {
        title: 'LAPs',
        key: 'RTM_LAP',
    },

]

export const FMM_MENU = [{
        title: 'LMPs',
        key: 'RTPD_LMP',
        name: 'RTPD_LMP',
    },
    {
        title: 'SP Tie Combination Prices',
        key: 'RTPD_SPTIE_LMP',
        name: 'RTPD_SPTIE_LMP',

    },
    {
        title: 'Flex Ramp Nodal Prices',
        key: 'RTPD_FLEX_RAMP_NODAL_PRC',
        name: 'RTPD_FLEX_RAMP_NODAL_PRC',

    },
    {
        title: 'Flex Ramp SP Tie Nodal Prices',
        key: 'RTPD_FLEX_RAMP_SPTIE_NODAL_PRC',
        name: 'RTPD_FLEX_RAMP_SPTIE_NODAL_PRC',

    },
    {
        title: 'MPM LMPs',
        key: 'RTPD_MPM_LMP',
        name: 'RTPD_MPM_LMP',

    },

]

export const HOUR_MENU = [{
        title: 'LMPs',
        key: 'HASP_LMP',
    },
    {
        title: 'AS Clearing Prices',
        key: 'HASP_PRC_AS',

    },
    {
        title: 'MPM LMPs',
        key: 'HASP_MPM_LMP',

    },
]

export const RTM_MENU = [{
    title: 'All Real Time Market Prices',
    key: 'RTM_ALL',
}]


