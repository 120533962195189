export const steelBlue = {
  50: "#E4F1FD",
  100: "#C6DCE9",
  200: "#AAC3D4",
  300: "#8BAABE",
  400: "#7497AD",
  500: "#5C849D",
  600: "#4F758B",
  700: "#406174",
  800: "#324E5E",
  900: "#203846",
};
export const blueGrey = {
  50: "#F7F9F9",
  100: "#EFF3F4",
  200: "#DFE3E5",
  300: "#B3BABC",
  400: "#869093",
  500: "#737E82",
  600: "#606E72",
  700: "#4F5E63",
  800: "#2E4349",
  900: "#01212D",
  A700: "#17323A",
  A800: "#0C2B37",
  A900: "#0C1B21",
};

export const brown = {
  50: "#FEDCB8",
  100: "#FEB99C",
  200: "#E8957A",
  300: "#C57154",
  400: "#AD5639",
  500: "#933B1E",
  600: "#873119",
  700: "#772411",
  800: "#68140D",
  900: "#570200",
};
export const deepOrange = {
  50: "#F2E6E3",
  100: "#EBC4B4",
  200: "#DE9E84",
  300: "#D17B56",
  400: "#C96233",
  500: "#C14B0E",
  600: "#B8460B",
  700: "#AC4008",
  800: "#9E3A06",
  900: "#853005",
};
export const orange = {
  50: "#FEF5E6",
  100: "#FEE5BF",
  200: "#FED597",
  300: "#F7BA63",
  400: "#F09930",
  500: "#E88C1C",
  600: "#E08012",
  700: "#D97400",
  800: "#CB5A00",
  900: "#BD4000",
};
export const yellow = {
  50: "#FFF8E1",
  100: "#FFECB3",
  200: "#FFE082",
  300: "#FED54F",
  400: "#FECA28",
  500: "#FEC007",
  600: "#FEB300",
  700: "#FEA000",
  800: "#FE8E00",
  900: "#FC7C19",
};
export const olive = {
  50: "#F4F6E8",
  100: "#E3E7C5",
  200: "#D0D8A1",
  300: "#BDC87E",
  400: "#AFBD62",
  500: "#A2B348",
  600: "#92A341",
  700: "#7D9037",
  800: "#6A7D2F",
  900: "#485C21",
  A700: "#3E481B",
};
export const lime = {
  50: "#F7FBE6",
  100: "#ECF3BF",
  200: "#DFED96",
  300: "#D2E66B",
  400: "#C8DF48",
  500: "#BFDA20",
  600: "#B3C818",
  700: "#A3B20D",
  800: "#939B00",
  900: "#797500",
};
export const lightGreen = {
  50: "#F1F7E4",
  100: "#DCEBBD",
  200: "#C4DD92",
  300: "#ABD064",
  400: "#97C73E",
  500: "#84BD00",
  600: "#74AD00",
  700: "#5F9A00",
  800: "#498700",
  900: "#1B6500",
};
export const green = {
  50: "#EEFAE6",
  100: "#CAEAB8",
  200: "#A6DA8A",
  300: "#81CA5C",
  400: "#5DBA2E",
  500: "#39AA00",
  600: "#1D9913",
  700: "#018725",
  800: "#067724",
  900: "#0A6623",
  A700: "#074919",
};
export const teal = {
  50: "#D7F6F0",
  100: "#99E8D8",
  200: "#66D2C5",
  300: "#33BCB3",
  400: "#00A6A0",
  500: "#00938E",
  600: "#00807C",
  700: "#02706B",
  800: "#05615C",
  900: "#07453F",
};
export const blue = {
  50: "#E6F5FF",
  100: "#B8E2FF",
  200: "#94D4FF",
  300: "#70C6FF",
  400: "#4DB8FF",
  500: "#29A9FF",
  600: "#059BFF",
  700: "#0087E0",
  800: "#0072BC",
  900: "#03519A",
};
export const indigo = {
  50: "#E6E8F3",
  100: "#BFC6E1",
  200: "#96A2CC",
  300: "#6D7EB8",
  400: "#4D63AA",
  500: "#2B499C",
  600: "#244293",
  700: "#1B3988",
  800: "#112F7C",
  900: "#011F66",
};
export const purple = {
  50: "#EDEAF6",
  100: "#D2CBE9",
  200: "#B5AADB",
  300: "#9887CC",
  400: "#8872C2",
  500: "#7A5FB7",
  600: "#6C4CAC",
  700: "#5E39A1",
  800: "#502696",
  900: "#301366",
};
export const red = {
  50: "#FDEBEE",
  100: "#FACCD2",
  200: "#E7999B",
  300: "#DB7375",
  400: "#E45454",
  500: "#E8443D",
  600: "#D93B3B",
  700: "#C32D2D",
  800: "#AE1F1F",
  900: "#991111",
};
