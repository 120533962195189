import React from "react";
import { useEffect, useState } from "react";

import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Footer from "./components/Footer";
import lightTheme from "./theme/light";

import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { AuthProvider } from "./context/AuthContext";

function App() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      try {
        // Start loading
        setLoading(true);

        // Fetch data from the API endpoint using environment variables
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiEndpoint = process.env.REACT_APP_API_GET_BUCKET_METADATA;
        const response = await fetch(`${apiUrl}${apiEndpoint}`);

        // Check if the response is successful
        if (!response.ok) {
          // If not successful, throw an error
          const message = `An error has occurred: ${response.status}`;
          throw new Error(message);
        }

        // Parse the response as JSON and set the data state
        const metadata = await response.json();
        setData(metadata);
      } catch (error) {
        // Log any errors that occur during data fetching
        console.log("Error getting data from metadata:", error);
      } finally {
        // Whether successful or not, stop loading
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // The empty dependency array ensures this effect runs only once, on component mount

  
    // Render the fetched data
    return (
      <div>
        <AuthProvider>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Header />
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={75} />
          </div>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Dashboard data={data} />
          </Box>
        )}
        <Footer />
      </ThemeProvider>
    </AuthProvider>
      </div>
    );

  
}

export default App;
