import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";



const size = {
  width: 350,
  height: 300,

  slotProps: {
    legend: {
      hidden: false,
      labelStyle: {
        fontSize: 12,
        fill: "black",
      },
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      itemMarkWidth: 10,
      itemMarkHeight: 10,
      markGap: 5,
      itemGap: 10,
    },
  },
};

const seriesProps = {
  innerRadius: 80,
  outerRadius: 60,
  cornerRadius: 5,
  cx: 175,
  cy: 100,
  highlightScope: { faded: "global", highlighted: "item" },
  faded: { innerRadius: 60, additionalRadius: -10, outerRadius: 50 },
};

export default function PieChartArc({ groupData, groupTitle }) {
  return (
    <Box sx={{ height: "100%" }}>
      <Typography sx={{ textAlign: "center" }} gutterBottom>
        {groupTitle}
      </Typography>

      <PieChart
        series={[
          {
            data: groupData.map((d) => ({
              label: d.groupName,
              id: d.groupName,
              value: d.fileCount,
            })),
            valueFormatter: (v, { dataIndex }) => {
              const { dateFrom, dateTo, fileCount } = groupData[dataIndex];
              return (
                <div>
                  <Divider orientation="vertical" flexItem />
                  <div>File Count: {fileCount}</div>
                  <div>
                    Date Range: {dayjs(dateFrom).format("MM/YYYY")} {" - "}
                    {dayjs(dateTo).format("MM/YYYY")}{" "}
                  </div>
                </div>
              );
            },
            ...seriesProps,
          },
        ]}
        {...size}
      />
    </Box>
  );
}
