import React, { useState } from "react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import dayjs from "dayjs";

function Contact() {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState(false);
  const [organization, setOrganization] = useState("");
  const [success, setSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const feedbackData = {
      emailId: emailId,
      comments: comments,
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      timestamp: dayjs().unix(),
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    };

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const apiEndpoint = process.env.REACT_APP_API_FEEDBACK;
      const response = await fetch(`${apiUrl}${apiEndpoint}`, options);

      // Check if the response is successful
      if (!response.ok) {
        setSuccess(false);
      } else {
        setSuccess(true);
        reset();
      }
    } catch (error) {
      // TypeError: Failed to fetch
      setSuccess(false);
      console.log("There was an error", error);
    }
  };

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmailId("");
    setComments("");
    setOrganization("");
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (e.target.validity.valid) {
      setFirstNameError(false);
    } else {
      setFirstNameError(true);
    }
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (e.target.validity.valid) {
      setLastNameError(false);
    } else {
      setLastNameError(true);
    }
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    if (e.target.validity.valid) {
      setCommentsError(false);
    } else {
      setCommentsError(true);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(newEmail));
  };

  const handleOrgChange = (e) => {
    setOrganization(e.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <ContactSupportIcon />
        </Avatar>
        <Typography variant="h1">Feedback</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {success ? (
              <Alert>
                Thank You For Your Feedback regarding our OASIS Group Data
                site. We are always striving for excellence and your insights
                help us improve
              </Alert>
            ) : (
              ""
            )}
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={firstName}
                error={firstNameError}
                onChange={handleFirstNameChange}
                helperText={
                  firstNameError
                    ? "Please enter your first name (letters and spaces only)"
                    : ""
                }
                inputProps={{
                  pattern: "[A-Za-z ]+",
                  maxLength: 15,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="Last Name"
                value={lastName}
                error={lastNameError}
                onChange={handleLastNameChange}
                helperText={
                  firstNameError
                    ? "Please enter your last name (letters and spaces only)"
                    : ""
                }
                inputProps={{
                  pattern: "[A-Za-z ]+",
                  maxLength: 15,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="emailId"
                label="Email"
                value={emailId}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Please enter a valid email" : ""}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="organization"
                label="Organization"
                name="organization"
                autoComplete="organization"
                onChange={handleOrgChange}
                value={organization}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="comments"
                label="Comments/Questions?"
                name="comments"
                multiline
                rows={8}
                value={comments}
                error={commentsError}
                onChange={handleCommentsChange}
                helperText={lastNameError ? "Please enter your comments" : ""}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          <Grid container justifyContent="center">
            <Grid item>Above information will be sent to California ISO.</Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Contact;
