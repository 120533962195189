import React from "react";
import GroupZipTable from "./GroupZipTable";
import dayjs from "dayjs";
import { GROUP_INFO } from "../constants";
const Market = ({ groupName, title, groupData}) => {
  
    return(
        <GroupZipTable groupName={groupName} properties={groupData}  groupInfo={GROUP_INFO[groupName]} maxDate={groupData != null && groupData.dateTo != null ? dayjs(groupData.dateTo) : dayjs()} />
    )
};



export default Market;