import * as React from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

function Footer() {
  return (
    <>
    
    <AppBar position="fixed" elevation={0} color="inherit" sx={{backgroundColor: "#f7f9f9", top: 'auto', bottom: 0 }} >
    <Divider sx={{height:2}}/>
      <Toolbar variant='dense' >
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body2" color="textSecondary">
          {'© '}
          {new Date().getFullYear()}
          {' California Independent System Operator. All rights reserved.'}
        </Typography>
      </Toolbar>
    </AppBar>
    </>
  )

}

export default Footer