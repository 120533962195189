// src/AuthContext.js
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    accessKey: null,
    secretKey: null,
    isLoggedIn: false,
  });

  const login = (accessKey, secretKey, isLoggedIn) => {
    setAuthData({ accessKey, secretKey, isLoggedIn });
  };

  const logout = () => {
    setAuthData({ accessKey: null, secretKey: null , isLoggedIn: false});
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);