import * as React from "react";
import {Stack } from "@mui/material";

import { DAM_MENU, FMM_MENU, HOUR_MENU, RTD_MENU } from "../constants";

import PieChartArc from "./PieChartArc";

// Function to filter data based on menu keys
const filterData = (data, menu) => {
  const filteredData = [];
  menu.forEach((menuItem) => {
    if (data[menuItem.key] && data[menuItem.key].fileCount) {
      filteredData.push(data[menuItem.key]);
    }
  });
  return filteredData;
};

export default function MarketStatistics({ data }) {
  const damData = filterData(data, DAM_MENU);
  const rtdData = filterData(data, RTD_MENU);
  const fmmData = filterData(data, FMM_MENU);
  const hourData = filterData(data, HOUR_MENU);

  return (
    <Stack direction="column" width="100%" textAlign="center" spacing={2}>
      <Stack direction="row" spacing={2}>
        <PieChartArc
          groupData={damData}
          groupTitle="Day Ahead Market"
          direction="right"
        />

        <PieChartArc
          groupData={hourData}
          groupTitle="HASP"
          direction="right"
        />

        
      </Stack>
      <Stack direction="row" spacing={2}>
        

        <PieChartArc
          groupData={fmmData}
          groupTitle="Fifteen Minute Market"
          direction="left"
        />

<PieChartArc
          groupData={rtdData}
          groupTitle="Five Minute Market"
          direction="left"
        />
      </Stack>
    </Stack>
  );
}
