import * as React from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Home from "./Home";
import { useState } from "react";
import { Collapse, Divider, ListItemIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DAM_MENU,
  FMM_MENU,
  HOUR_MENU,
  RTD_MENU,
  RTM_MENU,
} from "../constants";
import Market from "../lmpprices/MarketData";
import Contact from "./Contact";

import { ExpandLess, ExpandMore,OpenInNew as OpenInNewIcon, Home as HomeIcon,Info as InfoIcon, LightMode as LightModeIcon, AccessTime as AccessTimeIcon, Feedback as FeedbackIcon } from "@mui/icons-material";
import { blueGrey, blue } from "../theme/iso-colors";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const drawerWidth = 275;


const openPDF = () => {
  // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
  const pdfUrl = 'OASIS_Data_Portal_User_Guide_v1.pdf';
  window.open(pdfUrl, '_blank');
};

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 2,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px  ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", minWidth: 50 }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .0)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

function Dashboard(props) {
  const [selectedNavItem, setSelectedNavItem] = useState("home");
  const [selectedListItem, setSelectedListItem] = useState();
  const [open] = React.useState(true);
  const [damCollapseOpen, setDamCollapseOpen] = React.useState(false);
  const [rtmCollapseOpen, setRtmCollapseOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleListItemClick = (event, listItem, navItem) => {
    setSelectedListItem(listItem);
    setSelectedNavItem(navItem);
  };

  function handleDamClick() {
    setDamCollapseOpen(!damCollapseOpen);
    //setSelectedNavItem("dam");
    //setSelectedListItem("DAM_ALL");
    setRtmCollapseOpen(false);
  }

  function handleRtmClick() {
    setRtmCollapseOpen(!rtmCollapseOpen);
    //setSelectedNavItem("rtm");
    //setSelectedListItem("RTM_ALL");
    setDamCollapseOpen(false);
  }

  const handleNavItemClick = (navItem) => {
    setSelectedListItem("");
    setSelectedNavItem(navItem);
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRightWidth: 1,
          },
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        <Toolbar sx={{ m: { xs: 1, md: "none" } }} />

        <List>
          <ListItemButton onClick={() => handleNavItemClick("home")}>
            {"home" === selectedNavItem ? (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blue[900],
                }}
              >
                <HomeIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blueGrey[900],
                }}
              >
                <HomeIcon />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ pl: 1 }}
              primary={
                "home" === selectedNavItem ? (
                  <Typography variant="h4selected">Home</Typography>
                ) : (
                  <Typography variant="h4">Home</Typography>
                )
              }
            />
          </ListItemButton>

          <Divider />

          <ListItemButton onClick={() => handleDamClick()}>
            {"dam" === selectedNavItem ? (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blue[900],
                }}
              >
                <LightModeIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blueGrey[900],
                }}
              >
                <LightModeIcon />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ pl: 1 }}
              primary={
                "dam" === selectedNavItem ? (
                  <Typography variant="h4selected">Day Ahead Market</Typography>
                ) : (
                  <Typography variant="h4">Day Ahead Market</Typography>
                )
              }
            />
            {damCollapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={damCollapseOpen} timeout="auto" unmountOnExit>
            <List>
              {DAM_MENU.map((item) => (
                <ListItemButton
                  sx={{ pl: 2 }}
                  key={item.key}
                  selected={item.key === selectedListItem}
                  onClick={(event) =>
                    handleListItemClick(event, item.key, "dam")
                  }
                >
                  <ListItemText
                    sx={{ pl: 3 }}
                    primary={
                      item.key === selectedListItem ? (
                        <Typography variant="selected">{item.title}</Typography>
                      ) : (
                        <Typography>{item.title}</Typography>
                      )
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <Divider />

          <ListItemButton onClick={() => handleRtmClick()}>
            {"rtm" === selectedNavItem ? (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blue[900],
                }}
              >
                <AccessTimeIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blueGrey[900],
                }}
              >
                <AccessTimeIcon />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ pl: 1 }}
              primary={
                "rtm" === selectedNavItem ? (
                  <Typography variant="h4selected">Real Time Market</Typography>
                ) : (
                  <Typography variant="h4">Real Time Market</Typography>
                )
              }
            />
            {rtmCollapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={rtmCollapseOpen} timeout="auto" unmountOnExit>
            <List>
              {RTM_MENU.map((item) => (
                <ListItemButton
                  sx={{
                    pl: 2,
                  }}
                  key={item.key}
                  selected={item.key === selectedListItem}
                  onClick={(event) =>
                    handleListItemClick(event, item.key, "rtm")
                  }
                >
                  <ListItemText
                    sx={{ pl: 3 }}
                    primary={
                      item.key === selectedListItem ? (
                        <Typography variant="selected">{item.title}</Typography>
                      ) : (
                        <Typography>{item.title}</Typography>
                      )
                    }
                  />
                </ListItemButton>
              ))}
            </List>

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Five Minute</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {RTD_MENU.map((item) => (
                    <ListItemButton
                      key={item.key}
                      selected={item.key === selectedListItem}
                      onClick={(event) =>
                        handleListItemClick(event, item.key, "rtm")
                      }
                    >
                      <ListItemText
                        sx={{ pl: 3.5 }}
                        primary={
                          item.key === selectedListItem ? (
                            <Typography variant="selected">
                              {item.title}
                            </Typography>
                          ) : (
                            <Typography>{item.title}</Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Fifteen Minute</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {FMM_MENU.map((item) => (
                    <ListItemButton
                      key={item.key}
                      selected={item.key === selectedListItem}
                      onClick={(event) =>
                        handleListItemClick(event, item.key, "rtm")
                      }
                    >
                      <ListItemText
                        sx={{ pl: 2 }}
                        primary={
                          item.key === selectedListItem ? (
                            <Typography variant="selected">
                              {item.title}
                            </Typography>
                          ) : (
                            <Typography>{item.title}</Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>HASP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {HOUR_MENU.map((item) => (
                    <ListItemButton
                      key={item.key}
                      selected={item.key === selectedListItem}
                      onClick={(event) =>
                        handleListItemClick(event, item.key, "rtm")
                      }
                    >
                      <ListItemText
                        sx={{ pl: 2 }}
                        primary={
                          item.key === selectedListItem ? (
                            <Typography variant="selected">
                              {item.title}
                            </Typography>
                          ) : (
                            <Typography>{item.title}</Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Collapse>

          <Divider />

          <ListItemButton
            key="contact"
            selected={"contact" === selectedNavItem}
            onClick={(event) => handleNavItemClick("contact")}
          >
            {"contact" === selectedNavItem ? (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blue[900],
                }}
              >
                <FeedbackIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon
                sx={{
                  minWidth: 25,
                  color: blueGrey[900],
                }}
              >
                <FeedbackIcon />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ pl: 1 }}
              primary={
                "contact" === selectedNavItem ? (
                  <Typography variant="h4selected">Feedback</Typography>
                ) : (
                  <Typography variant="h4">Feedback</Typography>
                )
              }
            />
          </ListItemButton>

          <Divider />
     
     <ListItemButton onClick={openPDF}>
       <ListItemIcon sx={{ minWidth: 25, color: selectedNavItem === "user-guide" ? blue[900] : blueGrey[900] }}>
         <InfoIcon />
       </ListItemIcon>
       <ListItemText
         sx={{ pl: 1 }}
         primary={<Typography variant="h4">User Guide</Typography>}
       />
       <ListItemIcon sx={{ minWidth: 25, color: selectedNavItem === "user-guide" ? blue[900] : blueGrey[900] }}>
         <OpenInNewIcon fontSize="small" />
       </ListItemIcon>
     </ListItemButton>
   </List>  
      </Drawer>
      <Main open={open}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          {selectedNavItem === "home" && <Home data ={props.data} />}

          {DAM_MENU.map(
            (item) =>
              item.key === selectedListItem && (
                <Market
                  key={item.key}
                  groupName={item.key}
                  groupData={props.data[item.key]}
                />
              )
          )}

          {RTM_MENU.map(
            (item) =>
              item.key === selectedListItem && (
                <Market
                key={item.key}
                  groupName={item.key}
                  groupData={props.data[item.key]}
                />
              )
          )}
          {RTD_MENU.map(
            (item) =>
              item.key === selectedListItem && (
                <Market
                key={item.key}
                  groupName={item.key}
                  groupData={props.data[item.key]}
                />
              )
          )}
          {FMM_MENU.map(
            (item) =>
              item.key === selectedListItem && (
                <Market
                key={item.key}
                  groupName={item.key}
                  groupData={props.data[item.key]}
                />
              )
          )}
          {HOUR_MENU.map(
            (item) =>
              item.key === selectedListItem && (
                <Market
                key={item.key}
                  groupName={item.key}
                  groupData={props.data[item.key]}
                />
              )
          )}
          {selectedNavItem === "contact" && <Contact />}
        </Box>
      </Main>
    </>
  );
}

export default Dashboard;
