import React, { useMemo, useEffect, useState, useCallback } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Button,
  Stack,
  Typography,
  Alert,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Box from "@mui/material/Box";
import { useAuth } from "../context/AuthContext";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

const GroupZipTable = ({ groupName, groupInfo, maxDate }) => {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(maxDate);
  const [endDate, setEndDate] = useState(maxDate);

  const [error, setError] = useState(null);
  const { authData } = useAuth();
  const [currentPercentage, setPercentage] = useState(0);
  const [progress, setProgress] = useState("");

  const title = groupInfo.heading;
  const description = groupInfo.description;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");
      const apiUrl = process.env.REACT_APP_API_URL;
      const searchEndpoint = process.env.REACT_APP_API_SEARCH_URL;

      const apiEndpoint =
        `${apiUrl}${searchEndpoint}` +
        "?prefix=" +
        groupName +
        "&startDate=" +
        formattedStartDate +
        "&endDate=" +
        formattedEndDate;

      const response = await fetch(apiEndpoint);

      // Check if the response is successful (status code 2xx)
      if (response.ok) {
        const result = await response.json();
        setObjects(result);
      } else {
        // Handle error
        console.error("HTTP error! Status:", response.status);
      }
    } catch (err) {
      /// Handle network errors or other exceptions
      console.error("An error occurred while fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const downloadFilesWithProgress = async (fileDetails) => {
    const client = new S3Client({
      apiVersion: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET_VERSION,
      region: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET_REGION,
      credentials: {
        accessKeyId: authData.accessKey,
        secretAccessKey: authData.secretKey,
      },
    });
    let totalContentLength = 0;
    fileDetails.forEach((element) => {
      totalContentLength = totalContentLength + parseInt(element.Size);
    });

    setShowModal(true);
    const size = fileDetails.length;
    let i = 0;
    for (const file of fileDetails) {
      i++;
      setProgress("Downlading file(s) " + i + " of " + size);
      const command = new GetObjectCommand({
        Bucket: process.env.REACT_APP_OASIS_BULK_DATA_S3_BUCKET,
        Key: file.key,
        RequestPayer: "requester",
      });

      try {
        const response = await client.send(command);
        // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
        const webStream = await response.Body.transformToWebStream();
        const reader = webStream.getReader();
        const contentLength = response.ContentLength;
        const totalLength =
          typeof contentLength === "string"
            ? parseInt(contentLength)
            : contentLength;
        // Step 3: read the data
        let receivedLength = 0; // received that many bytes at the moment
        let chunks = []; // array of received binary chunks (comprises the body)
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          chunks.push(value);
          receivedLength += value.length;

          if (typeof totalLength === "number") {
            const step =
              parseFloat((receivedLength / totalLength).toFixed(2)) * 100;
            setPercentage(step);
          }
        }

        const blob = new Blob(chunks);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.fileName;
        function handleOnDownload() {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", handleOnDownload);
          }, 500);
        }
        a.addEventListener("click", handleOnDownload, false);
        a.click();
      } catch (err) {
        console.log(err);
      } finally {
        if (i === size) {
          setShowModal(false);
          setPercentage(0);
        }
      }
    }
  };

  const calculateFilesize = useCallback(({ table }) => {
    let size = table
      .getSelectedRowModel()
      .flatRows.reduce((sum, row) => sum + row.getValue("size"), 0);
    return size < 10000
      ? (size / 1000).toFixed(2) + " KB"
      : (size / 1000000).toFixed(2) + " MB";
  }, []);

  const handleStartDateChange = (newDate) => {
    if (newDate.isAfter(endDate)) {
      setError("Start date cannot be after end date");
    } else {
      setError(null);
      setStartDate(newDate);
    }
  };

  const handleEndDateChange = (newDate) => {
    if (newDate.isBefore(startDate)) {
      setError("End date cannot be before start date");
    } else {
      setError(null);
      setEndDate(newDate);
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "key", header: "File Name" },
      { accessorKey: "groupName", header: "Type" },
      { accessorKey: "oprDate", header: "Trade Date" },
      { accessorKey: "oprHour", header: "Trade Hour" },
      { accessorKey: "lastModified", header: "Last Modified" },
      { accessorKey: "size", header: "Size" },
    ],
    []
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="heading"> {title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} direction="column">
            <Typography variant="body1">{description}</Typography>
            <Stack spacing={3} direction="row" alignItems="center">
              <div></div>
              <Typography>Provide a Date Range : </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
              <Button
                disabled={error}
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Stack>
            {error && (
              <Alert severity="error" sx={{ width: "50%" }}>
                {" "}
                <b>Error:</b> {error}
              </Alert>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      &nbsp;
      <Dialog
        open={showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">c
          {"OASIS Group Data Download"}
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "80%", mr: 1 }}>
              <LinearProgress
                sx={{ height: 25 }}
                color="success"
                variant="determinate"
                value={currentPercentage}
              />
            </Box>
            {`${Math.round(currentPercentage)}%`}
          </Box>
          {progress}
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            DO NOT refresh or close the window.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <MaterialReactTable
        columnResizeDirection="rtl"
        columns={columns}
        data={objects}
        state={{
          expanded: true,
          isLoading: loading,
          showProgressBars: false,
          showSkeletons: loading,
        }}
        enableColumnResizing
        layoutMode="grid"
        selectAllMode="page"
        enableDensityToggle={false}
        enableRowSelection
        muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
        rowVirtualizerProps={{ overscan: 5 }}
        columnVirtualizerProps={{ overscan: 2 }}
        muiTableBodyRowProps={{ hover: true }}
        positionToolbarAlertBanner="bottom"
        initialState={{
          density: "compact",
          expanded: true,
          pagination: { pageIndex: 0, pageSize: 10 },
          columnVisibility: { key: false, size: false },
        }}
        muiTableProps={{
          sx: { border: "1px groove" },
        }}
        muiTableHeadCellProps={{
          sx: { border: "1px groove" },
        }}
        muiTableBodyCellProps={{
          sx: { border: "1px groove" },
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDownload = () => {
            let fileDetails = table
              .getSelectedRowModel()
              .flatRows.map((row) => row.original);
            downloadFilesWithProgress(fileDetails);
          };
          const handleDownloadAll = () => {
            downloadFilesWithProgress(objects);
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="success"
                disabled={
                  (!table.getIsSomeRowsSelected() &&
                    !table.getIsAllPageRowsSelected()) ||
                  !authData.isLoggedIn
                }
                onClick={handleDownload}
                variant="contained"
              >
                Download
              </Button>
              <Button
                variant="contained"
                disabled={!authData.isLoggedIn}
                onClick={handleDownloadAll}
              >
                Download All
              </Button>
              {authData.accessKey && authData.secretKey ? (
                <Alert severity="success">
                  File(s) Size: {calculateFilesize({ table })}.
                </Alert>
              ) : (
                <Alert severity="warning">
                  Login with AWS credentials to download the files
                </Alert>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default GroupZipTable;
