import { createTheme } from "@mui/material/styles";
import "@fontsource/urbanist"; // Defaults to weight 400

import {
    blue,
    blueGrey,
    brown,
    deepOrange,
    green,
    grey,
    indigo,
    orange,
    red,
    steelBlue,
    teal,
  } from "./iso-colors";


  

export const lightTheme= createTheme({
    typography: {
      fontFamily: "'Urbanist', sans-serif",
      h1: {
        fontSize: "2rem",
        fontWeight: 400,
        color: "#000",
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 500,
        color: "#000",
      },
      h3: {
        fontSize: "1.25rem",
        fontWeight: 600,
        color: "#000",
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 600,
        color: blueGrey[900],
      },
      h5: {
        color: "#000",
      },
      h6: {
        lineHeight: 1.2,
        color: "#000",
      },
      caption: {
        fontSize: ".8125rem",
      },
      heading: {
        fontSize: "1.125rem",
        fontWeight: 600,
        color: blue[900],
        
      },
      headingselected: {
        fontSize: "1.125rem",
        fontWeight: 600,
        color: blue[900],
        
      },
      selected: {
        fontSize: "1 rem",
        fontWeight: 600,
        color:blue[900],
      },
      notselected: {
        fontSize: "1 rem",
        fontWeight: 600,
        color: blueGrey[900],
      },
      h4selected: {
        fontSize: "1rem",
        fontWeight: 600,
        color: blue[900],
        
        textTransform: 'capitalize',
      },
      welcome:{
        fontWeight: 700,
        fontSize: "1.125 rem",
        lineHeight: "2",
      }
    
    },
    palette: {
      mode: "light",
      common: {
        black: "#000",
        white: "#fff",
      },
      primary: {
        light: blue[400],
        main: blue[800],
        dark: indigo[900],
      },
      secondary: {
        light: steelBlue[400],
        main: steelBlue[600],
        dark: steelBlue[900],
      },
      error: {
        light: red[600],
        main: red[900],
        dark: brown[900],
      },
      warning: {
        light: orange[300],
        main: orange[900],
        dark: deepOrange[700],
      },
      info: {
        light: teal[200],
        main: teal[600],
        dark: teal[900],
      },
      success: {
        light: green[300],
        main: green[900],
        dark: green["A700"],
      },
      text: {
        primary: "#000",
        secondary: blueGrey[600],
        disabled: `${blueGrey[800]}BF`,
      },
      neutral: {
        light: blueGrey[300],
        main: blueGrey[800],
        dark: blueGrey[900],
      },
      background: {
        default: "#ffffff",
        paper: blueGrey[50],
      },
      divider: blueGrey[200],
      contrastThreshold: 4.5,
    },
    shadows: [
      "none",
      //`0px 2px 1px -1px ${blueGrey[900]}1A,0px 1px 1px 0px ${blueGrey[900]}1A,0px 1px 3px 0px ${blueGrey[900]}1A`,
      `0px 0px 1px 1px ${blueGrey[900]}1A`,
      `0px 3px 1px -2px ${blueGrey[900]}1A,0px 2px 2px 0px ${blueGrey[900]}1A,0px 1px 5px 0px ${blueGrey[900]}1A`,
      `0px 3px 3px -2px ${blueGrey[900]}1A,0px 3px 4px 0px ${blueGrey[900]}1A,0px 1px 8px 0px ${blueGrey[900]}1A`,
      `0px 2px 4px -1px ${blueGrey[900]}1A,0px 4px 5px 0px ${blueGrey[900]}1A,0px 1px 10px 0px ${blueGrey[900]}1A`,
      `0px 3px 5px -1px ${blueGrey[900]}1A,0px 5px 8px 0px ${blueGrey[900]}1A,0px 1px 14px 0px ${blueGrey[900]}1A`,
      `0px 3px 5px -1px ${blueGrey[900]}1A,0px 6px 10px 0px ${blueGrey[900]}1A,0px 1px 18px 0px ${blueGrey[900]}1A`,
      `0px 4px 5px -2px ${blueGrey[900]}1A,0px 7px 10px 1px ${blueGrey[900]}1A,0px 2px 16px 1px ${blueGrey[900]}1A`,
      `0px 5px 5px -3px ${blueGrey[900]}1A,0px 8px 10px 1px ${blueGrey[900]}1A,0px 3px 14px 2px ${blueGrey[900]}1A`,
      `0px 5px 6px -3px ${blueGrey[900]}1A,0px 9px 12px 1px ${blueGrey[900]}1A,0px 3px 16px 2px ${blueGrey[900]}1A`,
      `0px 6px 6px -3px ${blueGrey[900]}1A,0px 10px 14px 1px ${blueGrey[900]}1A,0px 4px 18px 3px ${blueGrey[900]}1A`,
      `0px 6px 7px -4px ${blueGrey[900]}1A,0px 11px 15px 1px ${blueGrey[900]}1A,0px 4px 20px 3px ${blueGrey[900]}1A`,
      `0px 7px 8px -4px ${blueGrey[900]}1A,0px 12px 17px 2px ${blueGrey[900]}1A,0px 5px 22px 4px ${blueGrey[900]}1A`,
      `0px 7px 8px -4px ${blueGrey[900]}1A,0px 13px 19px 2px ${blueGrey[900]}1A,0px 5px 24px 4px ${blueGrey[900]}1A`,
      `0px 7px 9px -4px ${blueGrey[900]}1A,0px 14px 21px 2px ${blueGrey[900]}1A,0px 5px 26px 4px ${blueGrey[900]}1A`,
      `0px 8px 9px -5px ${blueGrey[900]}1A,0px 15px 22px 2px ${blueGrey[900]}1A,0px 6px 28px 5px ${blueGrey[900]}1A`,
      `0px 8px 10px -5px ${blueGrey[900]}1A,0px 16px 24px 2px ${blueGrey[900]}1A,0px 6px 30px 5px ${blueGrey[900]}1A`,
      `0px 8px 11px -5px ${blueGrey[900]}1A,0px 17px 26px 2px ${blueGrey[900]}1A,0px 6px 32px 5px ${blueGrey[900]}1A`,
      `0px 9px 11px -5px ${blueGrey[900]}1A,0px 18px 28px 2px ${blueGrey[900]}1A,0px 7px 34px 6px ${blueGrey[900]}1A`,
      `0px 9px 12px -6px ${blueGrey[900]}1A,0px 19px 29px 2px ${blueGrey[900]}1A,0px 7px 36px 6px ${blueGrey[900]}1A`,
      `0px 10px 13px -6px ${blueGrey[900]}1A,0px 20px 31px 3px ${blueGrey[900]}1A,0px 8px 38px 7px ${blueGrey[900]}1A`,
      `0px 10px 13px -6px ${blueGrey[900]}1A,0px 21px 33px 3px ${blueGrey[900]}1A,0px 8px 40px 7px ${blueGrey[900]}1A`,
      `0px 10px 14px -6px ${blueGrey[900]}1A,0px 22px 35px 3px ${blueGrey[900]}1A,0px 8px 42px 7px ${blueGrey[900]}1A`,
      `0px 11px 14px -7px ${blueGrey[900]}1A,0px 23px 36px 3px ${blueGrey[900]}1A,0px 9px 44px 8px ${blueGrey[900]}1A`,
      `0px 11px 15px -7px ${blueGrey[900]}1A,0px 24px 38px 3px ${blueGrey[900]}1A,0px 9px 46px 8px ${blueGrey[900]}1A`,
    ],
   
  });
  
  export default lightTheme